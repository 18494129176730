import { Col, Row } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { DeleteInstructions } from "../../components/plugins/models/DeleteInstructions";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { useVersion } from "../../components/plugins/contexts/VersionContext";
import { VersionDetailsForm } from "../../components/plugins/VersionDetailsForm";
import { DeleteVersionButtonGroup } from "../../components/plugins/DeleteVersionButtonGroup";
import { VersionDetailsPartial } from "../../components/plugins/VersionDetailsPartial";
import { PluginVersionTabs } from "../../components/plugins/PluginVersionTabs";
import { PendingVersionButtonGroup } from "../../components/plugins/PendingVersionButtonGroup";
import { useEffect } from "react";
import { Status } from "../../components/plugins/models/Status";
import { useNavigate } from "react-router-dom";
import { versionRoute } from "../../main/routes";
import { NotFound } from "../NotFound";

export const PendingVersion = () => {
  const { isLoading, plugin } = usePlugin();
  const { version } = useVersion();
  const navigate = useNavigate();
  const pendingVersion = version?.unpublishedPluginVersion ?? version;

  useEffect(() => {
    if (pendingVersion && pendingVersion.status !== Status.InReview) {
      navigate(versionRoute(plugin.id, version.versionId, plugin.status));
    }
  }, [pendingVersion]);

  if (isLoading) return <Loader />;
  if (!pendingVersion) return <NotFound />;

  return (
    <VersionDetailsForm version={pendingVersion}>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <PluginVersionTabs />
          <VersionDetailsPartial />
          <div className="d-flex mb-2">
            <PendingVersionButtonGroup />
            <DeleteVersionButtonGroup version={pendingVersion} futureInstructions={DeleteInstructions.One} />
          </div>
        </Col>
      </Row>
    </VersionDetailsForm>
  );
};
