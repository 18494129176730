import { Action } from "./components/plugins/models/Action";
import { DeleteAction } from "./components/plugins/models/DeleteAction";
import { Status } from "./components/plugins/models/Status";
import { stringFormat } from "./utils";

const notificationsRouteTemplate = "/notifications";
const changeNotificationStatusRouteTemplate = "/notifications/status/change";
const notificationsCountRouteTemplate = "/notifications/count";

const oneUserProfileRouteTemplate = "/profiles/one";
const allUserProfilesRouteTemplate = "/profiles/all?accountId={0}";
const updateUserProfileRoleRouteTemplate = "/accounts/role/change";
const removeUserProfileRouteTemplate = "/accounts/user/dismiss?userId={0}";
const userProfileSettingsRouteTemplate = "/profiles/settings";

const parentProductsRouteTemplate = "/settings/products/parents";
const updateParentProductRouteTemplate = "/settings/products/parents/update";
const deleteParentProductRouteTemplate = "/settings/products/parents/{0}/delete";
const productsRouteTemplate = "/settings/products";
const updateproductRouteTemplate = "/settings/products/update";
const deleteProductRouteTemplate = "/settings/products/{0}/delete";

const categoriesRouteTemplate = "/categories";
const udpdateCategoryRouteTemplate = "/settings/categories/update";
const deleteCategoryRouteTemplate = "/settings/categories/{0}/delete";

const exportPluginsRouteTemplate = "/settings/exports/plugins?isJsonFormat={0}";
const accountExportRouteTemplate = "/settings/exports/accounts";

const inviteUserRouteTemplate = "/accounts/user/invite";
const reinviteUserRouteTemplate = "/accounts/user/invite/resend?userId={0}";

const oneAccountRouteTemplate = "/accounts/one";
const updateAccountRouteTemplate = "/accounts/update";
const updateAccountSettingsRouteTemplate = "/accounts/settings";
const generateRepositoryGuidRouteTemplate = "/accounts/repository/guid";
const registerAccountRouteTemplate = "/accounts/register";
const extensiveAccountsRouteTemplate = "/accounts/all/extensive?userId={0}";
const minimalAccountsRouteTemplate = "/accounts/all/minimal?userId={0}";
const selectAccountRouteTemplate = "/authentication/account/select";
const favouriteAccountRouteTemplate = "/accounts/favourite";
const accountAgreementRouteTemplate = "/authentication/agreement";
const logoutRouteTemplate = "/authentication/logout";

const nameMappingsRouteTemplate = "/names";
const deleteNameMappingRouteTemplate = "/settings/names/{0}/delete";
const updateNameMappingRouteTemplate = "/settings/names/update";

const updateCommentRouteTemplate = "/plugins/comments/update";
const deleteCommentRouteTemplate = "/plugins/comments/delete?id={0}";
const deletePluginLogsRouteTemplate = "/plugins/logs/clear?pluginId={0}";

const pluginsRouteTemplate = "/plugins";
const pluginRouteTemplate = "/plugins/{0}";
const activatePluginRouteTemplate = "/plugins/{0}/status/active";
const deactivatePluginRouteTemplate = "/plugins/{0}/status/inactive";
const approvePluginRouteTemplate = "/plugins/request/approve";
const rejectPluginRouteTemplate = "/plugins/request/reject";
const deletePluginRouteTemplate = "/plugins/{0}/delete";
const requestDeletePluginRouteTemplate = "/plugins/{0}/delete/request";
const acceptDeletePluginRouteTemplate = "/plugins/{0}/delete/request/accept";
const rejectDeletePluginRouteTemplate = "/plugins/{0}/delete/request/reject";

const versionPath = "/plugins/{0}/versions/{1}";
const versionPathNoId = "/plugins/{0}/versions";
const extractPluginVersionRouteTemplate = "/plugins/{0}/versions/extract";
const downloadPluginVersionRouteTemplate = `${versionPathNoId}/{1}/download`;
const savePluginVersionRouteTemplate = `${versionPathNoId}/{1}`;
const activatePluginVersionRouteTemplate = `${versionPath}/status/active`;
const deactivatePluginVersionRouteTemplate = `${versionPath}/status/inactive`;
const approvePluginVersionRouteTemplate = `${versionPathNoId}/request/approve`;
const rejectPluginVersionRouteTemplate = `${versionPathNoId}/request/reject`;
const deletePluginVersionRouteTemplate = `${versionPath}/delete`;
const requestDeletePluginVersionRouteTemplate = `${versionPath}/delete/request`;
const acceptDeletePluginVersionRouteTemplate = `${versionPath}/delete/request/accept`;
const rejectDeletePluginVersionRouteTemplate = `${versionPath}/delete/request/reject`;

export const notificationsRoute = () => notificationsRouteTemplate;

export const changeNotificationStatusRoute = () => changeNotificationStatusRouteTemplate;

export const notificationsCountRoute = () => notificationsCountRouteTemplate;

export const oneUserProfileRoute = () => oneUserProfileRouteTemplate;

export const allUserProfilesRoute = (accountId: any) => stringFormat(allUserProfilesRouteTemplate, accountId);

export const updateUserProfileRoleRoute = () => updateUserProfileRoleRouteTemplate;

export const removeUserProfileRoute = (userId: any) => stringFormat(removeUserProfileRouteTemplate, userId);

export const userProfileSettingsRoute = () => userProfileSettingsRouteTemplate;

export const parentProductsRoute = () => parentProductsRouteTemplate;

export const updateParentProductRoute = () => updateParentProductRouteTemplate;

export const deleteParentProductRoute = (id: any) => stringFormat(deleteParentProductRouteTemplate, id);

export const productsRoute = () => productsRouteTemplate;

export const updateProductRoute = () => updateproductRouteTemplate;

export const deleteProductRoute = (id: any) => stringFormat(deleteProductRouteTemplate, id);

export const categoriesRoute = () => categoriesRouteTemplate;

export const updateCategoryRoute = () => udpdateCategoryRouteTemplate;

export const deleteCategoryRoute = (id: any) => stringFormat(deleteCategoryRouteTemplate, id);

export const exportPluginsRoute = (isJson: boolean) => stringFormat(exportPluginsRouteTemplate, isJson);

export const exportAccountsRoute = () => accountExportRouteTemplate;

export const inviteUserRoute = () => inviteUserRouteTemplate;

export const reinviteUserRoute = (userId: any) => stringFormat(reinviteUserRouteTemplate, userId);

export const oneAccountRoute = () => oneAccountRouteTemplate;

export const updateAccountRoute = () => updateAccountRouteTemplate;

export const updateAccountSettingsRoute = () => updateAccountSettingsRouteTemplate;

export const generateRepositoryGuidRoute = () => generateRepositoryGuidRouteTemplate;

export const registerAccountRoute = () => registerAccountRouteTemplate;

export const extensiveAccountsRoute = (userId: any) => stringFormat(extensiveAccountsRouteTemplate, userId);

export const minimalAccountsRoute = (userId: any) => stringFormat(minimalAccountsRouteTemplate, userId);

export const selectAccountRoute = () => selectAccountRouteTemplate;

export const logoutRoute = () => logoutRouteTemplate;

export const favouriteAccountRoute = () => favouriteAccountRouteTemplate;

export const accountAgreementRoute = () => accountAgreementRouteTemplate;

export const nameMappingsRoute = () => nameMappingsRouteTemplate;

export const deleteNameMappingRoute = (id: any) => stringFormat(deleteNameMappingRouteTemplate, id);

export const updateNameMappingRoute = () => updateNameMappingRouteTemplate;

export const updateCommentRoute = () => updateCommentRouteTemplate;

export const deleteCommentRoute = (id: any) => stringFormat(deleteCommentRouteTemplate, id);

export const deletePluginLogsRoute = (pluginId: any) => stringFormat(deletePluginLogsRouteTemplate, pluginId);

export const savePluginRoute = (action: Action) => stringFormat(pluginRouteTemplate, action);

export const getPluginsRoute = () => pluginsRouteTemplate;

export const getPluginRoute = (pluginId: any) => stringFormat(pluginRouteTemplate, pluginId);

export const pluginStatusRoute = (pluginId: any, status: Status) => {
  const template = status === Status.Published ? activatePluginRouteTemplate : deactivatePluginRouteTemplate;
  return stringFormat(template, pluginId);
};

export const pluginRequestRoute = (status: Status) => {
  return status === Status.Published ? approvePluginRouteTemplate : rejectPluginRouteTemplate;
};

export const pluginDeleteRoute = (pluginId: any, action: DeleteAction) => {
  switch (action) {
    case DeleteAction.Delete:
      return stringFormat(deletePluginRouteTemplate, pluginId);
    case DeleteAction.RequestDelete:
      return stringFormat(requestDeletePluginRouteTemplate, pluginId);
    case DeleteAction.ApproveDelete:
      return stringFormat(acceptDeletePluginRouteTemplate, pluginId);
    case DeleteAction.RejectDelete:
      return stringFormat(rejectDeletePluginRouteTemplate, pluginId);
  }
};

export const extractPluginVersionRoute = (pluginId: any) => stringFormat(extractPluginVersionRouteTemplate, pluginId);

export const downloadPluginVersionRoute = (pluginId: any, versionId: any) =>
  stringFormat(downloadPluginVersionRouteTemplate, pluginId, versionId);

export const savePluginVersionRoute = (pluginId: any, action: Action) =>
  stringFormat(savePluginVersionRouteTemplate, pluginId, action);

export const pluginVersionStatusRoute = (pluginId: any, versionId: any, status: Status) => {
  const template =
    status === Status.Published ? activatePluginVersionRouteTemplate : deactivatePluginVersionRouteTemplate;

  return stringFormat(template, pluginId, versionId);
};

export const pluginVersionRequestRoute = (pluginId: any, status: Status) => {
  return status === Status.Published
    ? stringFormat(approvePluginVersionRouteTemplate, pluginId)
    : stringFormat(rejectPluginVersionRouteTemplate, pluginId);
};

export const pluginVersionDeleteRoute = (pluginId: any, versionId: any, action: DeleteAction) => {
  switch (action) {
    case DeleteAction.Delete:
      return stringFormat(deletePluginVersionRouteTemplate, pluginId, versionId);
    case DeleteAction.RequestDelete:
      return stringFormat(requestDeletePluginVersionRouteTemplate, pluginId, versionId);
    case DeleteAction.ApproveDelete:
      return stringFormat(acceptDeletePluginVersionRouteTemplate, pluginId, versionId);
    case DeleteAction.RejectDelete:
      return stringFormat(rejectDeletePluginVersionRouteTemplate, pluginId, versionId);
  }
};
